import store from '@/store'


export const uploadImg = async data => {
  const formData = new FormData();

  formData.append("file", data);


  return await fetch("https://api.mz-biotech.com//api/file/uploadFiles", {
    method: "POST",
    headers: {
      Authorization: store.getters.token
    },
    body: formData
  }).then(body => body.json()).then(res => res);
}