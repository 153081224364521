<template>
  <div>
    <div class="title">核心技术 / {{uname}}</div>
    <div class="content" style="padding-left:20px">
      <el-menu active-text-color="
 #4A71AF" :default-active="activeIndex" mode="horizontal" @select="Selectchange">
        <el-menu-item index="1">Banner</el-menu-item>
        <el-menu-item index="2">分类管理</el-menu-item>
        <el-menu-item index="3">内容管理</el-menu-item>
      </el-menu>
      <component :is="zujian"></component>
    </div>

  </div>
</template>

<script>
import type from '@/views/Core/components/type'
import banner from '@/views/Core/components/banner'
import contentt from '@/views/Core/components/content'
export default {
  components: {
    banner,
    contentt,
    type
  },
  data () {
    return {
      //首页子标题
      uname: 'Banner',
      //动态组件切换名称
      zujian: 'banner',
      activeIndex: '1'
    }
  },
  methods: {
    Selectchange (index) {
      if (index == 1) {
        this.uname = 'Banner'
        this.zujian = 'banner'

      } else if (index == 2) {
        this.uname = '分类管理'
        this.zujian = 'type'
      } else if (index == 3) {
        this.uname = '内容管理'
        this.zujian = 'contentt'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  width: 1720px;
  height: 45px;
  background: #f2f2f2;
  text-align: left;
  line-height: 45px;
  padding-left: 20px;

  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af;
}
/deep/ .el-menu-item {
  line-height: 75px;
  padding: 0;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
  margin-right: 30px;
}
/deep/ .el-menu-item.is-active {
  border-bottom-color: rgb(74, 113, 175);
  border-width: 4px;
  border-radius: 2px;
}
/deep/ .el-menu.el-menu--horizontal {
  border-bottom: 2px solid rgba(74, 113, 175, 0.4);
}
</style>