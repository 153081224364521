<template>
  <div class="quill-wrap">
    <input class="quill-img" type="file" @change="uploade($event)"
      style="position: fixed; top: -9999px; left: -9999px;" />

    <quill-editor v-model="formD" ref="myQuillEditor" :options="editorOption" class="ql-editor">
    </quill-editor>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import { uploadImg } from '@/api/upload'


export default {

  components: { quillEditor },
  data () {
    return {
      content: '',
      // 富文本框参数设置
      editorOption: {
        modules: {
          // ImageExtend: {
          //   loading: true,
          //   name: 'file',
          //   action: 'http://api.mz-biotech.com/api/file/uploadFiles',
          //   headers: (xhr) => {
          //     xhr.setRequestHeader('Authorization', store.getters.token)
          //   },
          //   response: (res) => {
          //     return res.data
          //   }
          // },
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              ["blockquote", "code-block"], //引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: "ordered" }, { list: "bullet" }], //列表
              [{ script: "sub" }, { script: "super" }], // 上下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ font: [] }], //字体
              [{ align: [] }], //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              'image': function () {
                document.querySelector(".quill-img").click();
              }
            }
          }
        }
      }
    }
  },
  props: {
    formD: {},
  },
  methods: {
    async uploade (e) {
      const files = e.target.files;

      const res = await uploadImg(files[0])

      let quill = this.$refs.myQuillEditor.quill;  //获取富文本编辑器的编辑框
      quill.focus();     //聚焦在上面
      quill.insertEmbed(quill.getSelection().index, "image", res.data);

      document.querySelector(".quill-img").value = "";
    }
  }
}
</script>
<style lang="scss" scoped>
.quill-editor {
  height: 400px;
  min-height: 400px;
}
.ql-container {
  overflow: auto;
}
</style>